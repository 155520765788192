@import "../partials/base";
html, body{
    background: #fafafa;
    font-family: Lato;
}
* {
    font-family: Lato, sans-serif;
}
input {
    outline: none;
}
.btn {
    background: #f8d340;
    color: #444;
    border-radius: 99em;
    font-weight: bold;
    padding: 0;
    display: inline-block;
    width: 256px;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    letter-spacing: 2px;
    text-align: center;
    border: 0 none;
}
.btn:hover {
    background: #d3b022;
    color: #fff;
}
.btn:active:focus, .btn:focus {
    outline: 0;
}
a:hover {
    text-decoration: none;
}
.mobile {
    display: none;
}
div.home-title {
    position: relative;
    display: inline-block;
}
div.home-title h2 {
    font-size: 30px;
    letter-spacing: 1px;
    font-weight: bold;
    margin: 0;
    color: #324a5e;
    position: relative;
}
div.home-title:before {
    content: "";
    display: block;
    width: 110%;
    height: 70%;
    position: absolute;
    left: -5%;
    top: 13px;
    background: rgba(0, 196, 179, 0.2);
}
.header-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    height: 64px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 5;
    background: #fff;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 5px 0px;
}
.mobile-logo {
    display: none;
}
.logistics-logo img {
    height: 28px;
}
.logistics-nav a.navl {
    color: #000;
    font-size: 14px;
    margin-left: 36px;
}
.logistics-nav a.navl:hover {
    color: #00c4b3;
}
.logistics-nav a.btn {
    width: 150px;
    height: 40px;
    line-height: 40px;
    margin-left: 36px;
    font-size: 14px;
}
.logistics-wrap .btn:hover, .cf-btn-wrap .btn:hover {
    background: #d3b022;
    color: #fff;
}
.banner-wrap {
    position: relative;
    background-color: black;
    height: 100vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
}
.banner-wrap video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    transform: translateX(-50%) translateY(-50%);
}
.banner-area {
    position: relative;
    z-index: 2;
    text-align: center;
    justify-content: space-around;
    color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.banner-text {
    margin-top: 200px;
}
.logistics-title {
    font-size: 48px;
    font-weight: bold;
}
.logistics-desc {
    font-size: 26px;
}
.btn-wrap {
    margin-top: 0px;
}
.why-wrap {
    max-width: 1005px;
    margin: 0 auto;
    padding: 60px 20px 45px;
}
.for-title {
    text-align: center;
}
.why-list {
    
}
.why-list .item {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    padding: 44px;
    border: 1px solid #ebe9e9;
    background: #fff;
}
.why-list .item-img {
    width: 243px;
    text-align: center;
}
.why-list .item-img img {
    max-width: 100%;
    max-height: 180px;
}

.why-list .item-info {
    margin-left: 58px;
    flex: 1;
}
.why-list .item-title{
    font-size: 14px;
    color: #324a5e;
    font-weight: 500;
    margin-top: 20px;
}
.why-list .item-sub {
    font-size: 20px;
    color: #324a5e;
    font-weight: bold;
}
.why-list .item-desc {
    font-size: 15px;
    color: #999;
    margin-top: 12px;
}
.btn-get {
    margin-top: 35px;
    text-align: center;
}
.cust-wrapper {
    background: #e9f8f7;
}
.cust-wrap {
    max-width: 1005px;
    margin: 0 auto;
    padding: 60px 20px 45px;
}
.cust-list {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.cust-list .item {
    width: 19%;
    max-width: 190px;
    text-align: center;
    border: 1px solid #ecebeb;
    background: #fff;
    cursor: pointer;
}
.cust-list .item-bd {
    // height: 100%;
    border: 2px solid #fff;
    padding: 28px 0 34px;
}
.cust-list .item-img img {
    max-width: 110px;
    max-height: 98px;
}
.cust-list .item-name {
    margin-top: 16px;
    font-size: 22px;
    font-weight: bold;
    color: #324a5e;
}
html[lang=zh-tw] .cust-list .item-name {
    font-size: 16px;
}
@media screen and (max-width: 850px) and (min-width: 768px) {
    html[lang=zh-tw] .cust-list .item-name {
        font-size: 14px;
    }
}
.cust-list .item-link {
    margin-top: 20px;
    font-size: 13px;
    color: #9b9b9b;
    font-weight: bold;
}
.cust-list .item:hover {
    border: 1px solid #00c4b3;
}
.cust-list .item:hover .item-bd {
    border: 2px solid #00c4b3;
}
.cust-list .item:hover .item-link {
    color: #00c4b3;
}
.manage-wrap {
    max-width: 1005px;
    margin: 0 auto;
    padding: 60px 20px 45px;
}
.manage-list {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.manage-list .item {
    width: 32%;
    max-width: 288px;
    border: 1px solid #ebe9e9;
    background: #fff;
}
.manage-list .item.to-business:hover {
    border: 3px solid #00c4b3;
    cursor: pointer;
}
.manage-list .item.to-business:hover .item-img img {
    height: 239px;
}
.manage-list .item.to-business:hover .item-info {
    padding: 20px 8px 18px;
}
.manage-list .item-img img {
    width: 100%;
    height: 241px;
    object-fit: cover;
}
.manage-list .item-info {
    padding: 20px 10px;
}
.manage-list .item-name {
    font-size: 22px;
    font-weight: bold;
    color: #324a5e;
}
.manage-list .item-desc {
    font-size: 15px;
    color: #9b9b9b;
    font-weight: bold;
    padding-bottom: 8px;
}
.manage-list .item-li {
    font-size: 15px;
    color: #00c4b3;
    font-weight: bold;
    margin-top: 2px;
}
.manage-list .item-li img {
    height: 20px;
}
.touch-form {
    background: #00c4b3;
    text-align: center;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.f-title {
    font-size: 24px;
    font-weight: bold;
    color: #ffd729;
}
.f-desc {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}
.f-desc a {
    font-weight: bold;
    color: #fff;
}
.f-box {
    margin-top: 20px;
    display: flex;
}
.ipt-box {
    margin-right: 20px;
}
.ipt-box input {
    border: 0 none;
    border-left: 1px solid #cdfffb;
    border-bottom: 1px solid #cdfffb;
    background: transparent;
    width: 269px;
    height: 32px;
    line-height: 32px;
    padding-left: 12px;
    font-size: 16px;
    color: #fafafa;
}
.ipt-box input::placeholder {
    font-weight: bold;
    color: #fafafa;
}
.ipt-box input:focus {
    border: 0 none !important;
    border-left: 1px solid #cdfffb !important;
    border-bottom: 1px solid #cdfffb !important;
}
.ipt-box .btn {
    height: 32px;
    line-height: 32px;
    font-size: 12px;
    width: 159px!important;
}
/* popup item */
.item-popup .boxful-popup-content {
    height: 400px;
}
.item-popup .boxful-popup-close-button{
    top: -4px;
    left: 18px;
    right: inherit;
    color: #b9b9b9;
}
.customize-popup {
    display: flex;
    align-items: center;
    padding: 0 30px;
    height: 400px;
}
.cust-left {
    padding: 0 30px;
}
.cust-left img {
    max-width: 200px;
}
.cust-left .name {
    font-size: 22px;
    font-weight: bold;
    color: #324a5e;
    margin-top: 16px;
    text-align: center;
}
.cust-right {
    flex: 1;
    text-align: left;
    padding-right: 30px;
}
.dest-list {
    padding: 0;
    margin: 0;
    list-style: none;
}
.dest-list li {
    font-size: 18px;
    font-weight: bold;
    color: #4a4a4a;
    margin-bottom: 10px;
}
.dest-list li img {
    height: 24px;
    margin-right: 14px;
}
.cust-desc {
    border-top: 1px solid #d8d8d8;
    padding: 12px 0 0;
    color: #9b9b9b;
    font-size: 15px;
    font-weight: 500;
}
.enquire-popup .boxful-popup-content {
    height: 400px;
}
.enquire-popup .boxful-popup-close-button {
    top: -4px;
    left: 18px;
    right: inherit;
    color: #b9b9b9;
}
.enquire-popup .boxful-popup-content {
    height: inherit
}
.cust-form-popup {
    padding: 50px 0 60px;
    text-align: center;
}
.cf-title {
    font-size: 24px;
    font-weight: bold;
    color: #00c4b3;
}
.cf-desc {
    font-size: 16px;
    color: #000;
    font-weight: 500;
}
.cf-desc a {
    font-weight: bold;
    color: #000;
}
.cf-box {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}
.cf-ctl {
    width: 40%;
    max-width: 310px;
}
.cf-ctl:first-child {
    margin-right: 40px;
}
.cf-ctl input {
    width: 100%;
    height: 32px;
    line-height: 32px;
    border: 0 none;
    border-left: 1px solid #00c4b3;
    border-bottom: 1px solid #00c4b3;
    padding-left: 12px;
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box;
}
.cf-ctl input::placeholder {
    color: #6d7278;
}
.cf-btn-wrap {
    margin-top: 50px;
}
.cf-btn-wrap .btn {
    outline: 0;
}
.logistics-nav-bar, .logistics-shadow, .sider-bar-logistics {
    display: none;
}
.footer-top-bar {
    background: #00c4b3;
    padding: 0 30px;
    .footer-top-main {
        height: 80px;
        width: 100%;
        max-width: 970px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .footer-top-item {
            color: #fff;
            font-size: 20px;
            a {
                color: #fff;
            }
            img {
                height: 20px;
                margin-top: -3px;
                &.email, &.youtube {
                    height: 17px;
                }
            }
            &:last-child a {
                margin-left: 24px;
            }
            @media screen and (max-width: 960px) {
                font-size: 15px;
            }
        }
    }
}
.footer-main-wrap {
    background: #fafafa;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 73px;
    .foot-logo-wrap {
        padding-top: 38px;
        img {
            height: 30px;
        }
    }
    .footer-sep-line {
        height: 3px;
        background: #d8d8d8;
        width: 150px;
        margin: 40px auto 0;
    }
    .footer-logo-desc {
        font-weight: bold;
        font-size: 20px;
        margin-top: 20px;
    }
    .footer-link-wrap {
        display: flex;
        justify-content: center;
        margin-top: 26px;
        .flink {
            margin: 0 18px;
            a {
                font-size: 18px;
                font-weight: bold;
                color: #4a4a4a;
            }
        }
    }
    .footer-text-main {
        font-size: 15px;
        color: #949494;
        line-height: 1.47;
        margin-top: 14px;
        text-align: center;
        strong {
            color: #00c4b3;
        }
    }
    .footer-pay {
        margin-top: 3px;
        img {
            height: 48px;
            margin: 5px;
            &:first-child {
                height: 52px;
            }
            &:last-child {
                height: 32px;
            }
        }
    }
}
.pad-logo {
    display: none;
}
.hktvmall-bar-wrap {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    background: #f8d340;
    width: 100%;
    .pormotion-swiper-container {
        height: 40px;
    }
    a {
        display: block;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-weight: bold;
        color: #4a4a4a;
        font-size: 16px;
        strong {
            color: #00c4b3;
        }
        span {
            
        }
        em {
            font-weight: normal;
            font-style: normal;
            text-decoration: underline;
            font-size: 14px;
        }
    }
}
#outboundPromotion {
    .modal-dialog {
        max-width: 408px;
        @media screen and (max-width: 767px) {
            max-width: 300px;
            margin: 0 auto;
        }
    }
    .modal-content {
        box-shadow: 0px 4px 12px 3px rgba(0, 0, 0, 0.25);
        border: 0 none;
        border-radius: 10px;
        background: get_prefix_url("imgs/promotion/pbg_en.png") no-repeat;
        background-size: 100%;
        &.zh {
            background: get_prefix_url("imgs/promotion/pbg_zh.png") no-repeat;
            background-size: 100%;
        }
        .modal-body {
            padding: 0;
            button.close {
                color: #666;
                font-weight: bold;
                font-size: 40px;
                float: left;
                margin: 5px 0 0 16px;
            }
        }
    }
    .outbound-promotion-wrap {
        text-align: center;
        height: 459px;
        padding-top: 393px;
        @media screen and (max-width: 767px) {
            height: 338px;
            padding-top: 280px;
        }
        .btn {
            min-width: 293px;
            @media screen and (max-width: 767px) {
                width: 256px!important;
                padding: 6px 0 7px !important;
                min-width: auto;
            }
        }
    }
}

@media screen and (max-width: 900px) and (min-width: 768px) {
    .pad-logo {
        display: block;
    }
    .other-logo {
        display: none;
    }
    .logistics-nav a.btn {
        width: 100px;
        letter-spacing: 0;
        font-size: 12px;
        height: 32px;
        line-height: 32px;
    }
}
@media screen and (max-width: 1133px) {
    .logistics-logo img {
        height: 20px;
    }
}
@media screen and (max-width: 1133px) {
    .logistics-nav a.navl {
        margin-left: 5px;
    }
    .logistics-nav a.btn {
        margin-left: 5px;
    }
}
@media screen and (max-width: 899px) {
    .header-bar {
        padding: 0 20px;
    }
    
    .manage-list .item-img img {
        height: 180px;
    }
    .manage-list .item-name {
        font-size: 18px;
    }
    .manage-list .item-li {
        font-size: 12px;
    }
    .manage-list .item-info {
        padding: 10px;
    }
    .ipt-box input {
        width: 240px;
    }
    .cust-left img {
        max-width: 180px;
    }
    .dest-list li {
        font-size: 14px;
    }
    .cust-right {
        padding-right: 18px;
    }
}
@media (min-width: 768px) {
    .modal-dialog {
        max-width: 800px;
    }
}

@media screen and (max-width: 767px) {
    .hktvmall-bar-wrap {
        bottom: auto;
        top: 0;
        z-index: 12;
        a {
            line-height: 1;
            padding-top: 7px;
            strong {
                display: block;
                text-align: center;
                font-size: 12px;
            }
            span {
                font-size: 11px;
            }
            em {
                font-size: 10px;
            }
        }
    }
    .homepage {
        .header-bar {
            top: 40px;
        }
        .logistics-shadow {
            height: calc(100vh - 90px);
            top: 90px;
        }
        .sider-bar-logistics {
            top: 90px;
        }
    }
    .mobile {
        display: block;
    }
    .desktop {
        display: none;
    }
    .btn {
        display: block;
        font-size: 14px;
        border-radius: 99em !important;
        padding: 10px 0 !important;
        width: 312px !important;
        height: auto !important;
        line-height: inherit;
        margin: 0 auto;
    }
    div.home-title h2 {
        font-size: 20px;
    }
    .logistics-nav {
        display: none;
    }
    .header-bar {
        height: 50px;
    }
    .logistics-title {
        font-size: 40px;
    }
    .logistics-desc {
        font-size: 23px;
    }
    .why-list .item {
        flex-direction: column;
        padding: 20px 10px;
    }
    .why-list .item-img {
        width: 100%;
    }
    .why-list .item-img img {
        max-height: 130px;
    }
    .why-list .item-info {
        margin-left: 0;
        width: 100%;
    }
    .why-list .item-sub {
        font-size: 14px;
    }
    .why-list .item-desc {
        font-size: 14px;
    }
    .cust-list {
        flex-direction: column;
    }
    .cust-list .item {
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }
    .manage-list {
        flex-direction: column;
    }
    .manage-list .item {
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }
    .manage-list .item-name {
        font-size: 20px;
    }
    .manage-list .item-li {
        font-size: 14px;
    }
    .f-title {
        font-size: 16px;
    }
    .f-desc {
        font-size: 14px;
        margin-top: 5px;
    }
    .form-wrap {
        margin-top: 10px;
    }
    .f-box {
        flex-direction: column;
        margin-top: 0;
    }
    .ipt-box {
        margin-top: 20px;
        margin-right: 0;
    }
    .ipt-box input {
        width: 312px;
    }
    .ipt-box .btn {
        padding: 4px 0 !important;
        width: 312px !important;
        font-size: 14px;
        letter-spacing: 2px;
    }
    /* popup */
    .item-popup .boxful-popup-content {
        height: auto;
    }
    .customize-popup {
        flex-direction: column;
    }
    .cust-left {
        padding: 0 15px;
    }
    .cust-left img {
        max-width: 150px;
    }
    .cust-left .name {
        margin-top: 10px;
    }
    .cust-right {
        padding-right: 0px;
        margin-top: 6px;
    }
    .dest-list li {
        margin-bottom: 2px;
        font-size: 13px;
    }
    .cust-desc {
        margin-top: 20px;
        font-size: 13px;
        padding-bottom: 20px;
    }
    .dest-list li img {
        margin-right: 3px;
        height: 20px;
    }
    .customize-popup {
        padding: 0 15px;
    }
    .cf-title {
        font-size: 14px;
    }
    .cf-desc {
        font-size: 13px;
        margin-top: 5px;
    }
    .cf-box {
        flex-direction: column;
        align-items: center;
    }
    .cf-ctl:first-child {
        margin-right: 0;
    }
    .cf-ctl {
        width: 90%;
        margin-top: 16px;
    }
    .cf-box {
        margin-top: 0;
    }
    .cf-ctl input {
        font-size: 14px;
    }
    .cf-btn-wrap {
        margin-top: 16px;
    }
    .cf-btn-wrap .btn {
        width: 90% !important;
        max-width: 310px;
    }
    
    .footer-top-bar .footer-top-main {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 0;
        height: auto;
        .footer-top-item {
            margin: 5px 0;
        }
    }
    .footer-main-wrap {
        .foot-logo-wrap {
            padding-top: 20px;
        }
        .footer-sep-line {
            display: none;
        }
        .footer-logo-desc {
            font-size: 14px;
            margin-top: 10px;
        }
        .footer-link-wrap {
            margin-top: 30px;
            .flink {
                margin: 0 7px;
                a {
                    font-size: 14px;
                }
            }
        }
        .footer-text-main {
            font-size: 12px;
            p {
                margin-top: 10px;
            }
        }
    }
    .mobile-logo {
        display: block;
    }
    .desktop-logo {
        display: none;
    }
    .logistics-logo img {
        height: 30px;
    }
    .footer-main-wrap .foot-logo-wrap img {
        height: 45px;
    }
}

@media screen and (max-width: 370px) {
    .btn {
        width: 260px !important;
    }
}